import {
    trigger,
    state,
    style,
    animate,
    transition,
    query,
    keyframes,
    stagger,
    group,
  } from '@angular/animations';

  export const  SLIDE_UP_DOWN  = [
    trigger('slideUpDown', [
        state('1', style({
              'opacity': '1', 'visibility': 'visible'
            })),
            state('0', style({
              'opacity': '0', 'visibility': 'hidden'
            })),
        transition(':enter', [
          style({height: 0, opacity: 0}),
          animate('250ms ease-in', style({height: '*' })),
          animate('300ms ease-out', style({opacity: 1})),
        ]),
        transition(':leave', [
          style({height: '*', opacity: 1 }),
          animate('250ms ease-out', style({opacity: 0 })),
          animate('300ms ease-in-out', style({height: 0 }))
        ])
      //   state('1', style({
      //     'max-height': '*', 'opacity': '1', 'visibility': 'visible'
      //   })),
      //   state('0', style({
      //       'max-height': '0px', 'opacity': '0', 'visibility': 'hidden'
      //   })),
      //   transition('1 => 0', [group([
      //       animate('400ms ease-in-out', style({
      //           'opacity': '0'
      //       })),
      //       animate('600ms ease-in-out', style({
      //           'max-height': '0px'
      //       })),
      //       animate('700ms ease-in-out', style({
      //           'visibility': 'hidden'
      //       }))
      //   ]
      //   )]),
      //   transition('0 => 1', [group([
      //       animate('1ms ease-in-out', style({
      //           'visibility': 'visible'
      //       })),
      //       animate('600ms ease-in-out', style({
      //           'max-height': '*'
      //       })),
      //       animate('800ms ease-in-out', style({
      //           'opacity': '1'
      //       }))
      //   ]
      //   )])
       ])
    ];

    export const FLYIN = [
        trigger('flyIn', [
            transition('* => *', [
              query(':enter', style({ opacity: 0 }), {optional: true}),
              query(':enter', stagger('300ms', [
                animate('.6s ease-in', keyframes([
                  style({opacity: 0, transform: 'translateY(-75%)', offset: 0}),
                  style({opacity: .5, transform: 'translateY(35%)',  offset: 0.3}),
                  style({opacity: 1, transform: 'translateY(0)',     offset: 1.0}),
                ]))]), {optional: true}),
              query(':leave', stagger('300ms', [
                animate('.6s ease-out', keyframes([
                  style({opacity: 1, transform: 'translateY(0)', offset: 0}),
                  style({opacity: .5, transform: 'translateY(35%)',  offset: 0.3}),
                  style({opacity: 0, transform: 'translateY(-75%)',     offset: 1.0}),
                ]))]), {optional: true})
            ])
          ])
    ];

    export const APPEAR = [
        trigger('appear', [
            // state('', style({width: 120, transform: 'translateX(0)', opacity: 1})),
            transition('void => *', [
                style({width: 5, transform: 'translateX(10px)', opacity: 0}),
                group([
                    animate('0.3s 0.5s ease', style({
                        transform: 'translateX(0)', width: '*'
                    })),
                    animate('0.3s ease', style({
                        opacity: 1
                    }))
                ])
            ]),
            transition('* => void', [
                style({width: 10, transform: 'translateX(50px)', opacity: 0}),
                group([
                    animate('0.3s 0.1s ease-in-out', style({
                        transform: 'translateX(0)', width: '*'
                    })),
                    animate('0.3s ease', style({
                        opacity: 1
                    }))
                ])
            ]),
        ])
    ];
