import { throwError as observableThrowError, Observable, BehaviorSubject, Subject } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Injectable, OnInit } from "@angular/core";
import { Http, Headers, Response } from "@angular/http";
import "rxjs/Rx";
import { AuthService } from "../auth/auth.service";
import { Router } from "@angular/router";
import { MypageModel, OrganizationMember, AddressRegionModel, CountryModel, LanguageModel } from "../../helpers/models/index";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";

declare var appInsights: any;

@Injectable()
export class MyPageService {
  private hostPath: string = JSON.parse(environment.IDENTITY);

  public loading = true;
  public organizationMembers: OrganizationMember[] = new Array<OrganizationMember>();

  public MypageModel: MypageModel = new MypageModel(null);
  private MypageModelObservable: Observable<MypageModel> = new Observable<MypageModel>();
  private MypageModelSubject: BehaviorSubject<MypageModel> = new BehaviorSubject<MypageModel>(new MypageModel(null));

  public addressRegions: AddressRegionModel[] = new Array<AddressRegionModel>();
  public AddressRegionObservable: Observable<AddressRegionModel[]> = new Observable<AddressRegionModel[]>();
  public AddressModelSubject: BehaviorSubject<AddressRegionModel[]> = new BehaviorSubject<AddressRegionModel[]>(new Array<AddressRegionModel>());

  public countries: CountryModel[] = new Array<CountryModel>();
  public countriesObservable: Observable<CountryModel[]> = new Observable<CountryModel[]>();
  public countriesSubject: BehaviorSubject<CountryModel[]> = new BehaviorSubject<CountryModel[]>(new Array<CountryModel>());

  public languages: LanguageModel[] = new Array<LanguageModel>();
  public languagesObservable: Observable<LanguageModel[]> = new Observable<LanguageModel[]>();
  public languagesSubject: BehaviorSubject<LanguageModel[]> = new BehaviorSubject<LanguageModel[]>(new Array<LanguageModel>());

  constructor(
    public router: Router,
    private authHttp: HttpClient,
    private authService: AuthService
  ) {
    this.MypageModelObservable = this.MypageModelSubject.asObservable();
    this.AddressRegionObservable = this.AddressModelSubject.asObservable();
    this.countriesObservable = this.countriesSubject.asObservable();
    this.languagesObservable = this.languagesSubject.asObservable();

    if (this.authService.loggedIn()) {
      this.fillMypageModels();
      this.fillAddressRegions();
      this.fillCountries();
      this.fillLanguages();
    }
  }

  public getMypageModel() {
    return this.MypageModelObservable;
  }

  public getAddressRegions() {
    return this.AddressRegionObservable;
  }

  public getCountries() {
    return this.countriesObservable;
  }

  public getLanguages() {
    return this.languagesObservable;
  }

  public fillMypageModels() {
    console.log("MyPageService - Start fillMypageModels");
    this.loading = true;
    this.loadMyPageData().subscribe(
      data => {
        this.MypageModel = new MypageModel(data);
        this.MypageModelSubject.next(this.MypageModel);
        this.loading = false;
      },
      error => {
        console.error("MyPageService - Error in fillMypageModels");
      }
    );
    console.log("MyPageService - Completed fillMypageModels");
  }

  public fillAddressRegions() {
    console.log("MyPageService - Start fillAddressRegions");
    this.loading = true;
    this.loadAddressRegions().subscribe(
      data => {
        data.regions.forEach(element => {
          let o = new AddressRegionModel(element);
          this.addressRegions.push(o);
        });
        this.AddressModelSubject.next(this.addressRegions);
        this.loading = false;
      },
      error => {
        console.error("MyPageService - Error in fillAddressRegions");
      }
    );
    console.log("MyPageService - Completed fillAddressRegions");
  }

  public fillCountries() {
    console.log("MyPageService - Start fillCountries");
    this.loading = true;
    this.loadCountries().subscribe(
      data => {
        data.regions.forEach(element => {
          let o = new CountryModel(element);
          this.countries.push(o);
        });
        this.countriesSubject.next(this.countries);
        this.loading = false;
      },
      error => {
        console.error("MyPageService - Error in fillCountries");
      }
    );
    console.log("MyPageService - Completed fillCountries");
  }

  public fillLanguages() {
    console.log("MyPageService - Start fillLanguages");
    this.loading = true;
    this.loadLanguages().subscribe(
      data => {
        data.languages.forEach(element => {
          let o = new LanguageModel(element);
          this.languages.push(o);
        });
        this.languagesSubject.next(this.languages);
        this.loading = false;
      },
      error => {
        console.error("MyPageService - Error in fillLanguages");
      }
    );
    console.log("MyPageService - Completed fillLanguages");
  }

  public loadMyPageData(): Observable<any> {
    let url = this.hostPath + "Matchedin/Matchedin.Service/myPage";
    console.log("MyPageService - access", url);
    return this.authHttp.get<any>(url).pipe(
      map((result: any) => {
        return result;
      }),
      catchError(this.handleError)
    );
  }

  public saveMyPageData(model): Observable<any> {
    let url = this.hostPath + "Matchedin/Matchedin.Service/myPage";
    console.log("MyPageService - access", url);
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.authHttp.post<any>(url, model, { headers }).pipe(
      map((result: any) => {
        return result;
      }),
      catchError(this.handleError)
    );
  }

  public changePassword(model): Observable<any> {
    let url = this.hostPath + "cjsf/user/changepassword";
    console.log("MyPageService - access", url);
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.authHttp.post<any>(url, model, { headers }).pipe(
      map((result: any) => {
        return result;
      }),
      catchError(this.handleError)
    );
  }

  public loadAddressRegions(): Observable<any> {
    const model = { site: "min.matchedin.com", type: 3 };
    let url = this.hostPath + "cjsf/structure/addressregions";
    console.log("MyPageService - access", url);
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.authHttp.post<any>(url, model, { headers }).pipe(
      map((result: any) => {
        return result;
      }),
      catchError(this.handleError)
    );
  }
  public loadCountries(): Observable<any> {
    const model = { site: "min.matchedin.com", type: 0 };
    let url = this.hostPath + "cjsf/structure/addressregions";
    console.log("MyPageService - access", url);
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.authHttp.post<any>(url, model, { headers }).pipe(
      map((result: any) => {
        return result;
      }),
      catchError(this.handleError)
    );
  }

  public loadLanguages(): Observable<any> {
    const model = { site: "min.matchedin.com" };
    let url = this.hostPath + "Matchedin/Matchedin.Service/Structure/languages";
    console.log("MyPageService - languages", url);
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.authHttp.post<any>(url, model, { headers }).pipe(
      map((result: any) => {
        return result;
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: Response | any) {
    // Todo: We should use a remote logging infrastructure
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    let errMsg = error.message
      ? error.message
      : error.status
        ? `${error.status} - ${error.statusText}`
        : "Server error";
    console.error("my-page.service - Handled error: " + errMsg); // log to console for now
    return observableThrowError(error);
    // Todo: How to handle this properly in component?
  }
}
